<template>
  <hs-loading v-if="isLoading" />
  <hs-form v-else>
    <h5 class="auth-title">{{ $t('recover_password.form.title') }}</h5>
    <br />
    <template v-if="!requested">
      <p class="auth-subtitle">{{ $t('recover_password.form.subtitle') }}</p>
      <hs-group :label="$t('login.form.fields.email.name')" label-for="email">
        <hs-input id="email" type="email" v-model="userEmail" :state="null" readonly disabled />
      </hs-group>
      <hs-group :label="$t('recover_password.form.fields.school.select-school')" label-for="select-school">
        <hs-multiselect
          id="select-school"
          v-model="form.school"
          track-by="name"
          label="name"
          :placeholder="$t('login.form.fields.password.select.placeholder')"
          :options="schools"
          :searchable="false"
          :allow-empty="false"
          :show-labels="false"
        />
        <template v-if="!$v.form.school.$error">
          <hs-form-invalid-feedback :state="false" v-if="!$v.form.school.required">
            {{ $t('recover_password.form.validation.required') }}
          </hs-form-invalid-feedback>
        </template>
      </hs-group>

      <hs-button
        block
        class="auth-form-button"
        variant="primary"
        type="button"
        :disabled="$v.form.$invalid"
        @click="recoverUserPassword"
        >{{ $t('recover_password.form.buttons.recover') }}</hs-button
      >

      <a href="javascript:;" @click.prevent="backToEmail" class="back-to">
        <hs-icon variant="solid" icon="arrow-left" />
        {{ $t('recover_password.form.buttons.back_to_email') }}
      </a>
    </template>
    <template v-else>
      <hs-alert variant="success" :show="true" :fade="true" dismissible>
        {{ $t('recover_password.requested.success') }}
      </hs-alert>
      <p class="auth-subtitle">{{ $t('recover_password.requested.follow_instructions') }}</p>
      <router-link :to="{ name: 'SignUp' }" class="back-sign-up m-0">
        <hs-icon variant="regular" icon="arrow-left" />
        {{ $t('recover_password.requested.back_sign_up') }}
      </router-link>
    </template>
  </hs-form>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { hsForm, hsLoading } from '@/components';
import ToastHelper from '@/shared/helpers/toast';
import { authApi } from '@/services';

export default {
  components: {
    hsForm,
    hsLoading,
  },
  props: {
    userEmail: String,
  },
  data() {
    return {
      form: {
        school: null,
      },
      isLoading: false,
      requested: false,
    };
  },
  computed: {
    ...mapState('school', {
      schools: state => state.items,
    }),
  },
  validations: {
    form: {
      school: {
        required,
      },
    },
  },
  methods: {
    backToEmail() {
      this.$emit('backToEmail');
    },
    recoverUserPassword() {
      this.isLoading = true;
      authApi
        .recoverPassword({ email: this.userEmail, schoolId: this.form.school.id })
        .then(() => (this.requested = true))
        .catch(() => ToastHelper.dangerMessage(this.$t('recover_password.form.toast.error')))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.back-sign-up {
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.166667px;
  color: #7f7f7f !important;
}
</style>
