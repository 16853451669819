<template>
  <div>
    <hs-loading v-if="isLoading" />
    <hs-form v-else @submit="submit">
      <h5 class="auth-title">{{ $t('recover_password.form.title') }}</h5>
      <p class="auth-subtitle">{{ $t('recover_password.form.subtitle') }}</p>

      <b-form-group>
        <label>{{ $t('recover_password.form.fields.email.name') }}</label>
        <hs-input
          id="recoverPassword_email"
          type="email"
          :placeholder="$t('recover_password.form.fields.email.placeholder')"
          @blur="$v.form.email.$touch()"
          v-model="form.email"
          :state="!$v.form.email.$error ? null : false"
        >
          <template slot="feedback" v-if="$v.form.email.$error">
            <hs-form-invalid-feedback :state="false">
              <span v-if="!$v.form.email.required">
                {{ $t('recover_password.form.validation.required') }}
              </span>
              <span v-if="!$v.form.email.email">
                {{ $t('recover_password.form.validation.email_type') }}
              </span>
            </hs-form-invalid-feedback>
          </template>
        </hs-input>
      </b-form-group>

      <Captcha ref="invisibleCaptcha" @verify="onVerify($event)" @error="onError" />

      <hs-button
        block
        class="auth-form-button"
        variant="primary"
        type="submit"
        :disabled="$v.form.$invalid || loadingBtn"
      >
        <b-spinner variant="gray" v-if="loadingBtn" small />
        {{ $t('recover_password.form.buttons.request') }}
      </hs-button>

      <router-link :to="{ name: 'Login' }" class="back-to">
        <hs-icon variant="solid" icon="arrow-left" />
        {{ $t('recover_password.form.buttons.back_to_login') }}
      </router-link>
    </hs-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import { hsForm, hsLoading } from '@/components';
import Captcha from '@/components/Captcha/index.vue';

import ToastHelper from '@/shared/helpers/toast';

export default {
  components: {
    hsForm,
    hsLoading,
    Captcha,
  },
  data() {
    return {
      form: {
        email: null,
      },
      isLoading: false,
      loadingBtn: false,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    ...mapActions('school', ['getSchools']),
    submit() {
      this.loadingBtn = true;
      this.$refs.invisibleCaptcha.execute();
    },
    async onVerify(captcha_token) {
      try {
        this.isLoading = true;

        const email = this.form.email;
        const res = await this.getSchools({ email, captcha_token });

        if (res.length > 0) {
          this.$emit('emailChosen', email);
        } else {
          ToastHelper.dangerMessage(this.$t('login.form.toast.no_schools'));
        }
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('login.form.toast.no_schools'));
      } finally {
        this.isLoading = false;
      }
    },
    onError() {
      this.loadingBtn = false;
    },
  },
};
</script>
