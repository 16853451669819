<template>
  <structure-auth :shape="require('@/assets/images/reset_password/reset_password_shape_vector.svg')">
    <StepChooseEmail v-if="step === 1" @emailChosen="emailChosen" />
    <StepChooseSchool v-else-if="step === 2" :user-email="email" @backToEmail="backToEmail" />
  </structure-auth>
</template>

<script>
import StructureAuth from '@/auth/components/Auth';
import StepChooseEmail from './StepChooseEmail';
import StepChooseSchool from './StepChooseSchool';

export default {
  components: {
    StepChooseEmail,
    StepChooseSchool,
    StructureAuth,
  },
  data() {
    return {
      isLoading: false,
      step: 1,
      email: null,
    };
  },
  methods: {
    backToEmail() {
      this.email = null;
      this.step = 1;
    },
    emailChosen(email) {
      this.email = email;
      this.step = 2;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .back-to {
  color: $grey !important;
  font-size: 14px;
  line-height: 150%;
  font-weight: bold;

  &:hover {
    color: $grey;
  }
}
</style>
